import request from '@/utils/request'

const organsationApi = {
    updateorgansation: '/api/updateorganisation',
    organisationinfo: '/api/organisation'

}

export function updateorganisation(parameter) {
    return request({
        url: organsationApi.updateorgansation,
        method: 'post',
        data: parameter
    });
}

export function getorganisation(id) {
    return request({
        url: organsationApi.organisationinfo + '/' + id,
        method:'get'
    });
}
