<template>
	<div>
		<!-- User Profile Card -->
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}" style="margin-top:0;padding:10px">
            <a-row type="flex" align="middle" v-show='!isedit'>
                <a-col :span="24" :md="12" class="col-info">
                    <div class="avatar-info">
                        <h4 class="font-semibold m-0">{{companyName}}</h4>
                        <p style="color:white;text-transform:capitalize">{{customerType}}</p>
                    </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                    <a-button type="primary" icon="edit" @click='isedit=true' shape="round" v-if='isadmin'>Edit</a-button>
                </a-col>
            </a-row>
              
            <a-row v-show='isedit'>
                <a-form layout='horizontal' 
                    :form="form" 
                    style="padding:0 24px" 
                    :label-col="{span:5}" 
                    :wrapper-col="{span:12}"
                    @submit="handleSubmit"
                    >
                <a-col>
                     <a-form-item label="Name">
                        <a-input v-decorator="['companyname']"></a-input>
                     </a-form-item>
                </a-col>
                <a-col>
                    <a-form-item label=" ">
                        <a-button type="primary" html-type="submit" icon="edit" size="small">Save</a-button>
                        <a-button icon="close" style="margin-left:10px;" @click='isedit=false' size="small">Close</a-button>
                    </a-form-item>
                </a-col>
                </a-form>
            </a-row>
		</a-card>
		<!-- User Profile Card -->
        <a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}" style="margin-top:0;padding:10px">
            <a-row type="flex" align="middle">
                <a-col :span="24" :md="12" class="col-info">
                    <div class="avatar-info">
                        <h4 class="font-semibold m-0">Members</h4>
                    </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                    <a-button type="primary" icon="plus" shape="round" v-if='isadmin'>Invite</a-button>
                </a-col>
            </a-row>
            <a-row >
                <a-table :columns="columns" :data-source="members" :pagination="false" v-show="members.length">
                </a-table>
            </a-row>
		</a-card>
	</div>
</template>

<script>
    import {updateorganisation, getorganisation} from '@/api/organisation';

	export default ({
		components: {
			
		},
        mounted(){
            this.form.setFieldsValue({companyname:this.companyName});
            getorganisation(this.$store.state.user.info.companyid).then(data=>{
                this.members = data;
            })
        },
		data() {
			return {
                form: this.$form.createForm(this,{name: 'org'}),
				username:this.$store.state.user.name,
				customerType: this.$store.state.user.info.companytype,
				companyName: this.$store.state.user.info.companyname,
                isadmin: this.$store.state.user.info.role=='admin',
                isedit:false,
                members:[],
                columns : [{title:'username',key:'gvalue',dataIndex:'gvalue'}]
			}
		},
        methods: {
            handleSubmit(e){
                e.preventDefault();
                this.form.validateFields((err,values)=>{
                    if (!err){
                        console.log(values);
                        updateorganisation(values).then(data=>{
                            this.isedit = false;
                            this.companyName = values.companyname
                        });
                    }
                });
            },
        }
	})

</script>

<style lang="scss">
</style>